/* Apply styles for larger screens */
@media (min-width: 768px) {
      .parent {
        margin: 1rem;
        text-align: center;
      }
    
      .child {
        display: inline-block;
        vertical-align: middle;
        padding: 20px;
      }
  }
  
  /* Apply styles for smaller screens */
  @media (max-width: 767px) {
    .parent {
      flex-direction: column;
      align-items: center;
    }
  
    .child {
      text-align: center;
      padding: 10px; /* Reduce padding on smaller screens */
    }
  }
  