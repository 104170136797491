.button-download {
    width: 50%;
    background-color: #3b77d8;
    border: none;
    border-radius: 5%;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    transition-duration: 0.5s;
  }
  .button-download:hover {
    background-color: #02ac46;
    color: white;
  }