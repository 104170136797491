/* header css */
.header {
  text-align: center;
  color: #f2f2f2;
}

.nav {
  background: #3b77d8;
  height: 85px;
  display: flex;
  justify-content: space-between;
  padding: 0.2rem calc((100vw - 1000px) / 2);
  z-index: 12;
  position: relative; /* Add relative positioning to .nav */
}

.link {
  color: #f2f2f2;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;

  &.active {
    background-color: #12507f;
  }
}

.navMenu {
  display: flex;
  align-items: center;
  margin-right: -24px;
}

.navLogo {
  color: #f2f2f2;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  text-decoration: underline;
}

/* Hamburger menu icon */
.hamburger {
  display: none; /* Hide the hamburger icon initially */
  align-items: center;
  cursor: pointer;
  font-size: 1.5rem;
}


/* Media query for smaller screens, e.g., mobile */
@media screen and (max-width: 768px) {
  .nav {
    flex-direction: row; /* Stack items vertically */
    height: auto; /* Auto adjust height based on content */
    padding: 1rem; /* Add more padding for better spacing */
  }

  /* Hide the menu items on mobile */
  .navMenu {
    display: none;
  }

  .navLogo {
    justify-content: center; /* Center the logo on mobile */
    margin-left: 1.2rem;
  }

  .hamburger {
    display: flex; /* Show the hamburger menu icon on mobile */
    padding: 10px;
    padding-top: 18px;
  }

  /* Show the menu items when the hamburger icon is active */
  .navMenu.active {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 100%; /* Position below the .nav */
    left: 0; /* Align with the left edge of .nav */
    background-color: #3b48d8; /* Match the background color of .nav */
    width: 100%; /* Occupy the full width of .nav */
    padding: 1rem; /* Add padding for better spacing */
  }

  /* Increase font size for navMenu links when hamburger is active */
  .navMenu.active .link {
    font-size: 1.6rem;
    margin-right: 20px;
  }
}
