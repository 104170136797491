.fade-in {
    opacity: 0;
    transition: opacity 1s ease-in-out;
}
  
.fade-in.active {
    opacity: 1;
}

/* Additional animation classes */
.animationLeft {
    opacity: 0;
    transform: translateX(-100%);
    transition: opacity 1s ease, transform 1s ease;
}

.animationLeft.active {
    opacity: 1;
    transform: translateX(0);
}

.animationRight {
    opacity: 0;
    transform: translateX(100%);
    transition: opacity 1s ease-in-out, transform 1s ease-in-out;
}

.animationRight.active {
    opacity: 1;
    transform: translateX(0);
}


.animationBottom {
    opacity: 0;
    transform: translateY(100%);
    transition: opacity 1s ease-in-out, transform 1s ease-in-out;
}

.animationBottom.active {
    opacity: 1;
    transform: translateY(0);
}

.animationTop{
    opacity: 0;
    transform: translateY(-100%);
    transition: opacity 1s ease-in-out, transform 1s ease-in-out;
}

.animationTop.active {
    opacity: 1;
    transform: translateY(0);
}