/* project css */
.projectlinks {
    color: #f2f2f2;
    text-align: center;
}

.items {
    border: solid black;
    background-color: blueviolet;
    margin: 2%;
    padding: 1%;
    color: #f2f2f2;
    display: inline-block;
    vertical-align: top;
    box-sizing: border-box;
    width: calc(50% - 4%); /* Calculate width with margin accounted for */
}

/* Responsive Styles for Items */
@media screen and (max-width: 767px) {
    .items {
        margin: 2%;
        margin-bottom: 1%; /* Adjusted margin for smaller screens */
        padding: 4%; /* Reduced padding for smaller screens */
        width: calc(100% - 4%); /* Full width on smaller screens */
    }
}

/* Responsive Styles for Images */
.items img {
    max-width: 100%; /* Make images responsive by limiting their width */
    height: auto; /* Maintain aspect ratio */
}

/* Responsive Styles for Headings */
.items h1, .items h2, .items h3, .items h4, .items h5, .items h6  {
    color: #f2f2f2;
    font-size: 1.5rem; /* Adjust heading font size for responsiveness */
    margin-top: 0.5rem; /* Add spacing between headings and content */
}

.items p{
    color: #f2f2f2;
    text-align: justify;
}

.items a{
    text-decoration: none;
}
