.skills{
    color: #f2f2f2;
}

/* Apply styles for larger screens */
@media (min-width: 768px) {
    .skills .child h1,
    .skills .child p,
    .skills .child ul,
    .skills .child li{
        text-align: justify;
        width: 500px;
    }
}

/* Apply styles for smaller screens */
@media (max-width: 767px) {


    .skills .parent .child .list-coding ul li  {
        list-style: none;
        text-align: center;
    }

    .skills .parent .child .list-coding h1  {
        text-align: center;
    }

    .skills .child h1,
    .skills .child ul,
    .skills .child li{
        text-align: left;
        width: auto;
        padding-right: 10px;
        padding-bottom: 10px;
    }

   
}