.FeedBackForm {
    text-align: center;
    color: #f2f2f2;
}

.textarea-submit{
  border: 1px solid #ccc;
  padding: 10px;
  width: 90%; /* Make the textarea full-width */
  height: 200px; /* Set the desired height */
  resize: none; /* Prevent resizing by the user */
  box-sizing: border-box; /* Include padding and border in the width */
  font-size: 16px; /* Adjust the font size as needed */
  line-height: 1.5; /* Adjust line height for better readability */
}

.button-submit {
    width: 50%;
    background-color: #3b77d8;
    border: none;
    border-radius: 5%;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    transition-duration: 0.5s;
  }
  .button-submit:hover {
    background-color: #02ac46;
    color: white;
  }