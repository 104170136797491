/* Home (with app names) css */
.App {
  text-align: center;
  color: #f2f2f2;
}

/* Apply styles for larger screens */
@media (min-width: 768px) {
  .App .child h1,p{
    text-align: justify;
    width: 450px;
  }
}

/* Apply styles for smaller screens */
@media (max-width: 767px) {

  .App .child h1{
    text-align: center;
  }
  
  .App .child p{
    text-align: justify;
  }
}

.App-logo-wrapper-center {
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.App-logo-circular {
  height: 40vmin;
  border-radius: 50%;
}
.App-logo {
  height: 40vmin;
}

