/* Common styles */
.info-box {
    padding: 15px;
    border-radius: 5px;
    margin: 10px;
    display: inline-block;
    vertical-align: top;
    background-color: #333; /* Background color for info boxes */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.info-box h2 {
    margin-top: 0;
    border-bottom: 2px solid #2f3154; /* Divider line below title */
    padding-bottom: 8px;
    color: #2f3154; /* Title color */
}

.info-box ul {
    margin: 3px;
    padding-left: 20px;
    max-height: 100px;
    width: auto;
    overflow: hidden;
}

.show-more-link {
    color: #0733d0;
    text-decoration: underline;
    cursor: pointer;
    display: block;
    margin-top: 5px;
}

/* for larger screen */
@media (min-width: 768px) {
    .info-box {
        border: 1px solid #2f3154; /* Border color for larger screens */
        width: calc(50% - 20px);
    }

    .info-box ul, h4 {
        text-align: justify;
    }
}

/* for smaller screen */
@media (max-width: 767px) {
    .info-box {
        border: 1px solid #ccc; /* Border color for smaller screens */
        width: auto;
    }

    .info-box ul {
        text-align: justify;
    }
}
